.p-toast-message {
  border-radius: 0 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08) !important;
  border: 1px solid $background !important;
  overflow: visible !important;

  .phone-call & {
    background: $white !important;
  }

  .toast-error & {
    border: none !important;
    background: $alert !important;
  }

  .toast-success & {
    border: none !important;
    background: $blue !important;
  }
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0;
}

.phone-call .p-toast-message-content {
  flex-direction: column;
}

.p-toast-icon-close-icon {
  color: $white;
}

.p-toast .p-toast-message .p-toast-icon-close {
  box-shadow: none !important;
  border-radius: 0 !important;

  &:hover {
    color: $white;
    background: rgba($white, 0.3);
  }

  &:active {
    background: transparent;
  }
}

.p-toast {
  opacity: 1 !important;
  z-index: 50000 !important;
  touch-action: none;
  //width: auto !important;
  //max-width: 25rem !important;

  phone-call & {
    width: 450px;
  }

  &.phone-incoming {
    z-index: 10000 !important;
    width: 350px;
  }

  &.phone-calling {
    z-index: 10000 !important;
    width: 350px;
  }

  &.phone-active {
    z-index: 9000 !important;
  }

  &.phone-finish {
    width: 500px;
  }

  &.phone-calling-finished-without-active {
  }
}

.p-toast-top-right {
  right: 47px !important; // 30px padding + 17px scrollbalken
  //top: 60px !important;
}

.phone-call-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($white, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-call-header {
  border-bottom: 1px solid $background;
  //padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  user-select: none;

  i {
    font-style: normal;
  }

  &:active {
    background: $hoverLight;
  }

  .pi {
    cursor: pointer !important;
  }

  .pi-minus,
  .pi-plus {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: $hoverLight;
    }

    &:active {
      background: darken($hoverLight, 5%);
    }
  }

  span {
    padding: 10px;
    color: $dark;
    font-size: 14px;
    font-weight: 600;
  }
}

.phone-call-footer {
  border-top: 1px solid $background;
  padding: 10px;
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;

  .pi {
    cursor: pointer;

    &:active {
      opacity: 0.6;
    }
  }
}

[styleclass="phone-checkbox-container"] {
  width: 160px;
}

.phone-call-footer-checkitems {
  display: flex;
  flex-direction: column;
}

.phone-call-body {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  textarea {
    margin: 10px 0 0 0;
    height: 70px;
  }

  .p-dropdown {
    width: 100%;
    margin: 10px 0 0 0;
  }

  .phone-call-open-btn {
    font-size: 15px;
    color: $gray;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .phone-call-detail {
    font-size: 15px;
    font-weight: bold;
    color: $dark;
  }

  .phone-call-detail-small {
    font-size: 14px;
    color: $lightDark;
  }
}

.toast-error,
.toast-success {
  .p-toast-message-icon {
    display: none;
  }

  .p-message-icon {
    display: none;
  }

  .p-toast-message-text {
    margin: 15px !important;
  }

  .p-toast-summary {
    color: $white !important;
  }

  .p-toast-detail {
    margin: 0 !important;
    color: rgba($white, 0.85) !important;
  }
}
