.p-button {
  background: $gradient;
  border: 2px solid transparent !important;
  border-radius: 0;
  font-size: 14px;
  padding: 5px 15px !important;
  text-decoration: none;

  &:enabled:hover {
    background: $gradient;
    box-shadow: 0 0 0 3px rgba($main2, 0.5);
  }

  &:enabled:active {
    background: $gradient;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 1px rgba($white, 0.8) inset !important;
  }

  &:focus:not(:hover) {
    box-shadow: 0 0 0 1px rgba($white, 0.8) inset !important;
  }
}

.p-button-danger {
  background: $alert !important;

  &:enabled:hover {
    box-shadow: 0 0 0 3px rgba($alert, 0.5);
  }
}

.p-button-warning {
  background: $orange !important;
  //color: $white !important;

  &:enabled:hover {
    box-shadow: 0 0 0 3px rgba($orange, 0.5);
  }
}

.p-button-info {
  background: $blue !important;

  &:enabled:hover {
    box-shadow: 0 0 0 3px rgba($blue, 0.5);
  }
}

.p-button.p-button-outlined {
  background: transparent !important;
  color: $dark;
  border: 2px solid $lightHighlight !important;

  &:enabled:hover {
    color: $dark;
    box-shadow: 0 0 0 3px rgba($lightHighlight, 0.5);
  }

  &:enabled:active {
    color: $dark;
  }

  &.background-white {
    background: $white !important;
  }
}

.p-button.p-button-outlined-light {
  background: transparent !important;
  color: $white;
  border: 2px solid $white !important;

  &:enabled:hover {
    color: $white;
    box-shadow: 0 0 0 3px rgba($white, 0.5);
  }

  &:enabled:active {
    color: $white;
  }
}

.p-button.p-button-small {
  padding: 0 6px !important;
  font-size: 13px !important;
}

.p-button.p-button-dark {
  background: $gray !important;
  border: none !important;
  font-size: 15px !important;
  box-shadow: none !important;

  &:hover {
    background: $lightHighlight !important;
  }

  &:active {
    background: $gray !important;
  }

  &:active,
  &:focus {
    box-shadow: none !important;
  }

  &:focus:not(:hover) {
    box-shadow: none !important;
  }

  &.active {
    background: $main1 !important;
  }
}

.p-button.p-button-text {
  background: transparent !important;
  border: none !important;
  color: $dark !important;

  &:enabled:hover {
    color: $main1 !important;
    box-shadow: none !important;
  }

  &:enabled:active {
    color: $gray !important;
    box-shadow: none !important;
  }
}

.button-without-padding {
  padding: 0 !important;
}

.p-calendar-w-btn .p-datepicker-trigger {
  padding: 0;
}

.p-button-label {
  flex-shrink: 0;
}

.btn-is-highlighted-alert {
  background: $alert !important;
  color: $white !important;

  .p-multiselect-trigger-icon {
    color: $white !important;
  }
}
