.content-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
  min-height: 60px;

  .fullscreen-mode & {
    display: none;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 690px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    justify-content: center;
  }

  .content-actions-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.clickable-pi-icon {
  cursor: pointer;

  &:active {
    opacity: 0.6;
  }
}

.icon-link {
  text-decoration: none !important;
}

.arrow-navigation {
  display: flex;
  align-items: center;
  position: absolute;
  //top: 15px;
  right: 0;

  > p-dropdown {
    margin: 0 0 0 20px;
  }

  > .pi-chevron-left,
  > .pi-search,
  > .pi-chevron-right {
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $dark;
    }

    &:active {
      color: $gray;
    }
  }

  > .pi-chevron-right {
    margin: 0 0 0 20px;
  }

  > .pi-search {
    margin: 0 30px 0 0;
  }
}

.pflegeherzen-logo {
  background: url(/assets/img/pflegeherzen-logo.png) no-repeat;
  width: 25px;
  height: 31px;
}

.pflegeherzen-logo-icon {
  background: url(/assets/img/pflegeherzen-logo-icon.png) no-repeat;
  width: 16px;
  height: 20px;
  display: block;
}

.parking-icon {
  background: url(/assets/img/parking.svg);
  width: 24px;
  height: 24px;
  display: flex;
}

.shop-icon {
  background: url(/assets/img/shop.svg);
  width: 24px;
  height: 24px;
  display: flex;
}

.heart-icon-small {
  background: url(/assets/img/heart-small.svg);
  width: 24px;
  height: 24px;
  display: flex;
}

.light-symbol {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  display: flex;
  margin: 0 10px 0 0;

  &:last-child {
    margin: 0;
  }

  th & {
    float: left;
  }
}

.light-symbol-dark {
  background: $dark;
}

.light-symbol-gray {
  background: $lightGray;
}

.light-symbol-white {
  background: $white;
}

.light-symbol-red {
  background: $alert;
}

.light-symbol-orange {
  background: $orange;
}

.light-symbol-green {
  background: $main2;
}

.light-symbol-blue {
  background: $blue;
}

h2 {
  font-weight: 600;
  display: flex;
  align-items: center;
  color: $dark;
  font-size: 20px;

  span {
    color: $gray;
    font-size: 16px;
    margin: 0 0 0 20px;
  }

  i {
    margin: 0 0 0 10px;
  }

  .tio {
    margin: 0 10px 0 0;
    font-size: 32px;
  }

  img {
    margin: 0 10px 0 0;
  }
}

// TODO: media query 100%?
.limit-width {
  max-width: 1100px;
}

.jodit-container:not(.jodit_inline) {
  background: #fff;
}

.jodit-wysiwyg table tr td,
.jodit-wysiwyg table tr th {
  padding: 0 !important;
}

.jodit-wysiwyg table {
  margin: 0 !important;
}

.jodit-container {
  max-height: 300px;
  overflow-y: auto;
  font-size: 14px;

  .bigger-jodit-font-size & {
    font-size: 15px;
  }
}

.jodit-container:not(.jodit_inline) {
  border-radius: 0 !important;
}

.jodit-toolbar__box:not(:empty) {
  border-radius: 0 !important;
}

.content-container {
  padding: 10px 20px;
  height: calc(100vh - #{$headerHeight});
  overflow-y: scroll;
  min-width: $minWidthContent;
  width: 100%;

  .local-system & {
    height: calc(100vh - #{$headerHeight} - #{$headerBannerHeight});
  }

  .local-system.secondary-view & {
    height: calc(
      100vh - #{$headerHeightSecondaryView} - #{$headerBannerHeight} - #{$navHeight}
    );
  }

  .secondary-view & {
    height: calc(100vh - #{$headerHeightSecondaryView} - #{$navHeight});
    min-width: auto;

    @media (max-width: 1000px) {
      height: calc(100vh - #{$headerHeightSecondaryView});
      padding: 30px 15px;
    }
  }
}

.content-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.no-wrap {
    flex-wrap: nowrap;
  }

  .content {
    width: calc(50% - 10px);
    margin: 0 0 20px 0;
  }

  .handle {
    height: 100%;
    cursor: move;
    display: flex;
    align-items: center;
    color: $gray;
  }
}

.content-offer-container {
  display: flex;
  width: 100%;
}

.content-offer-forms {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-inner-container {
  display: flex;
  flex-direction: column;
  border: 2px solid $background;
  width: 100%;
  padding: 10px 20px;
  margin: 10px 10px 20px 10px;
  justify-content: center;

  .p-field-radiobutton {
    margin-bottom: 0 !important;
  }

  > label {
    font-size: 14px;
    margin: 0 30px 0 0;
  }
}

.content-offer-budget {
  display: flex;
  margin: 0 0 0 30px;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
}

.budgets {
  position: sticky;
  top: 0;
}

.budget-container {
  padding: 10px 20px;
  color: $white;

  &.budget-container-1 {
    background: $blue;
  }

  &.budget-container-2 {
    background: $main2;
  }

  &.light-container {
    background: $lightGray;

    h3 {
      color: $dark;
      font-weight: 600;
    }
  }

  h3 {
    font-size: 14px;
    text-transform: uppercase;
    color: rgba($white, 0.8);
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pi-chevron-up,
    .pi-chevron-down {
      cursor: pointer;

      &:active {
        opacity: 0.6;
      }
    }
  }
}

.content-alert-message {
  display: block;
  background: $alert;
  padding: 5px 5px;
  text-align: center;
  color: $white;
  font-size: 13px;

  &.with-margin {
    margin: 10px 0 0 0;
  }
}

.budget-items {
  margin: 8px 0 0 0;
  display: flex;
  flex-direction: column;
}

.alert-highlight {
  background: rgba($alert, 0.1);
}

.budget-information-comment {
  font-size: 14px;
  padding: 5px 0 0 0;
}

.budget-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px 0;
  border-bottom: 1px solid rgba($white, 0.2);

  .pi,
  .content-alert-message {
    margin-left: auto;
  }

  &:hover {
    background: rgba($white, 0.1);
  }

  &:last-child {
    border: none;
  }
}

.budget-type {
  font-size: 15px;
  font-weight: 600;
  width: 40%;
  display: flex;
  align-items: center;
  //flex-direction: column;

  i {
    font-style: normal;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    margin: 0 0 0 10px;
  }
}

.budget-value {
  font-size: 15px;
}

.content-sidebar {
}

.content {
  width: 100%;
  //height: 100vh;
  margin: 0 0 30px 0;
  //border-radius: 3px;

  &.content-margin-right {
    margin-right: 30px;
  }

  &.border-highlight-1 {
    border: 4px solid $blue;
  }

  &.border-highlight-2 {
    border: 4px solid $main2;
  }

  &.content-highlight-1 {
    border-left: 10px solid $blue;
  }

  &.content-highlight-2 {
    border-left: 10px solid $main2;
  }

  &.is-sticky {
    position: sticky;
    top: -10px;
    z-index: 20;
  }

  &.w-1\/3 {
    width: 33.33%;
  }

  &.w-2\/3 {
    width: 66.66%;
  }

  &.w-2\/4 {
    width: 50%;
  }

  &.has-min-width {
    min-width: $minWidthContent;
  }

  &.stretch-full-min {
    min-height: 100vh;
  }

  &.stretch-full-max {
    height: calc(
      100vh - 200px
    ); // diese ergeben sich durch paddings, header höhe, etc
  }

  &.stretch-full-max-with-tabs {
    height: calc(
      100vh - 250px
    ); // diese ergeben sich durch paddings, header höhe, etc
  }

  p-table {
    height: 100%;
    width: 100%;
  }

  .p-datatable-scrollable {
    height: 100%;
  }

  .p-datatable-scrollable-view {
    height: 100%;
  }

  .p-datatable-scrollable-wrapper {
    height: 100%;
  }

  .p-datatable-scrollable-body {
    max-height: calc(100% - 25px) !important;
  }
}

.content-inner {
  display: block;
  margin: 0 0 0 20px;
}

.content-legend {
  display: flex;
  margin: 5px;

  span {
    font-size: 13px;
    color: $dark;
    margin: 0 10px 0 0;

    &:last-child {
      margin: 0;
    }
  }
}

.content-footer {
  width: 100%;
  margin: 0 0 100px 0;
  padding: 30px 0 0 0;
  display: flex;
  justify-content: space-between;
  //border-top: 2px solid darken($background, 15%);

  &.sticky {
    position: sticky;
    bottom: 0;
  }
}

.content-no-message {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: $gray;

  &.simple-text {
    display: block;
    text-align: center;
  }

  &.with-padding {
    padding: 20px 0;
  }
}

.content-arrow-left {
  position: absolute;
  top: 0;
  left: 0;
}

.content-arrow-right {
  position: absolute;
  top: 0;
  right: 0;
}

.content-header-tabs {
  height: $contentHeaderHeight;
  display: flex;
  position: relative;

  .fullscreen-mode & {
    display: none;
  }

  &.disabled,
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  > span,
  > a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px;
    color: $lightDark;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin: 0 10px 0 0;
    user-select: none;
    text-decoration: none;

    &:last-child {
      margin: 0;
    }

    &:hover {
      background: rgba($white, 0.3);
    }

    &:active {
      background: rgba($white, 0.6);
    }

    .p-tag {
      margin: 0 0 0 10px;
    }

    &.active {
      background: $white;
      color: $main1;
    }

    .disabled & {
      pointer-events: none;
    }
  }
}

.content-header-action-icons {
  display: flex;
  align-items: center;
}

.content-header-action-icon {
  cursor: pointer;
  padding: 0 10px;

  &:active {
    opacity: 0.6;
  }
}

.content-header-patients-tabs {
  display: flex;
  height: 100%;

  span,
  a {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $dark;
    }

    &:active {
      color: $gray;
    }

    &.active {
      color: $main1;
      font-weight: 600;
    }

    &:first-child {
      margin: 0 40px 0 0;
    }

    .pi {
      margin: 0 10px 0 0;
    }

    .p-tag {
      margin: 0 0 0 10px;
    }
  }
}

.content-header-text-action-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.content-header-text-action {
  display: flex;
  cursor: pointer;
  align-items: center;

  .pi-pencil {
    opacity: 0;
    margin: 0 0 0 10px !important;
    font-size: 14px;
    color: $gray;
  }

  .pi-info-circle {
    margin: 0 0 0 5px !important;
    font-size: 14px;
    color: $gray;
  }

  &:active {
    opacity: 0.6;
  }

  &:hover {
    .pi {
      opacity: 1;
    }
  }
}

.setting-menu-container {
  position: relative;

  .p-menu {
    right: 0 !important;
    left: unset !important;
    top: 100% !important;
  }

  .p-menu-overlay {
    background: $white;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    padding: 0;
    width: 240px;
    border: 1px solid $background;
    border-radius: 0;
  }

  .p-menu .p-menu-separator {
    margin: 0 !important;
  }

  .p-menuitem-link {
    padding: 10px 15px;
    box-shadow: none !important;
  }

  .p-menuitem-text {
    color: $dark !important;
    font-size: 14px;
  }

  .p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: $hoverLight;
  }

  .p-menu .p-menuitem-link:not(.p-disabled):active {
    background: darken($hoverLight, 10%);
  }
}

.content-header-info {
  color: $lightDark;
  font-size: 13px;
}

.content-header-message {
  background: $blue;
  color: $white;
  padding: 10px;
  text-align: center;
  font-size: 16px;
}

.header-small-infos {
  gap: 20px !important;

  small {
    font-weight: normal;
  }

  > span {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column !important;
  }
}

.content-header {
  background: $white;
  height: $contentHeaderHeight;
  display: flex;
  align-items: center;
  padding: 0 5px 0 20px;
  border-bottom: 1px solid $background;
  justify-content: space-between;
  //position: relative;

  .new-content-container & {
    background: transparent;
    padding: 0 5px;
  }

  &.content-header-smaller {
    height: $contentHeaderSmallerHeight;
  }

  .content-light-version & {
    background: none;
  }

  &.is-sticky {
    position: sticky;
    top: -10px;
    z-index: 20;
  }

  .pi-chevron-right,
  .pi-chevron-left {
    cursor: pointer;

    &:active {
      opacity: 0.6;
    }
  }

  &.without-border {
    border: none;
  }

  &.sticky {
    position: sticky;
    z-index: 100;
    top: -10px; // margin beachten
  }

  > span {
    color: $dark;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;

    .new-content-container & {
      font-weight: normal;
      font-size: 15px;
    }

    > span {
      display: flex;
      align-items: center;
    }

    > .pi {
      margin: 0 10px 0 0;
    }

    .pi-info-circle {
      margin: 0 0 0 10px;
    }

    .p-checkbox-icon {
      margin: 0;
    }

    p-checkbox {
      font-size: 14px;
      margin: 0 0 0 20px;
      font-weight: normal;
    }
  }
}

.sub-content-header {
  p-checkbox {
    &:first-child {
      margin: 0 0 0 25px;
    }
  }
}

.content-header-menu-tabs {
  display: flex;
  height: 100%;
  overflow-x: auto;

  &.disabled,
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  a {
    color: $dark;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 30px 0 0;
    border-bottom: 3px solid transparent;

    @media (max-width: 520px) {
      margin: 0 15px 0 0;
    }

    &:hover {
      border-bottom: 3px solid $lightGray;
    }

    &:active {
      border-bottom: 3px solid $gray;
    }

    &.active {
      border-bottom: 3px solid $main1;
      color: $main1;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.content-header-buttons {
  display: flex;
  align-items: center;

  p-checkbox {
    font-size: 14px;
    margin: 0 20px;
    font-weight: normal;
  }

  button,
  a {
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }
  }
}

.initial-documents-container {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content-no-message {
    margin: 30px 0 0 0;
  }
}

.content-sub-headline {
  font-size: 14px;
  font-weight: 600;
  color: $gray;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  display: flex;
}

.content-loading {
  opacity: 0.4;
}

.content-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  height: calc(100% - #{$contentHeaderHeight});
  transition: opacity 0.2s linear 0s;

  @media (max-width: 960px) {
    flex-direction: column !important;
  }

  &.no-transition {
    transition: none !important;
  }

  &.has-loading-logo {
    justify-content: center;
    align-items: center;
  }

  .content-light-version & {
    background: none;
    box-shadow: none;
  }

  &.content-base-height {
    height: 240px;
  }

  &.dialog-max-height {
    max-height: 600px;
    overflow: auto;
    box-shadow: none;
  }

  &.dialog-max-height-smaller {
    max-height: 200px;
    height: 100%;
    overflow: auto;
    box-shadow: none;
  }

  &.content-body-full-height {
    height: calc(100vh - 240px);

    .local-system & {
      height: calc(100vh - 240px - #{$headerBannerHeight});
    }
  }

  &.content-body-full-height-with-tabs {
    height: calc(100vh - 280px);

    .local-system & {
      height: calc(100vh - 280px - #{$headerBannerHeight});
    }
  }

  &.content-body-full-height-with-subtabs {
    height: calc(100vh - 320px);

    .local-system & {
      height: calc(100vh - 320px - #{$headerBannerHeight});
    }
  }

  &.scrollable-y {
    overflow-y: scroll;
  }

  .content-highlight & {
    //background: $hoverLight;
  }

  &.auto-height {
    height: auto !important;
  }

  &.without-padding {
    padding: 0;
  }

  .border-right {
    border-right: 1px solid $background;
  }

  .border-left {
    border-left: 1px solid $background;
  }

  .border-bottom {
    border-bottom: 1px solid $background;
  }
}

.budget-times {
  display: flex;
  border-bottom: 1px solid $background;

  .p-field {
    margin: 0;
  }

  .p-inputtext {
    padding: 2px 10px !important;
  }
}

.budget-time {
  padding: 5px 10px;
  display: flex;
  border-right: 1px solid $background;

  &:last-child {
    //border: none;
  }
}

.budget-content-body {
  flex-direction: row;
  box-shadow: none;

  p-table {
    border-right: 1px solid $background;
    width: 100%;

    &:last-child {
      border: none;
    }
  }
}

.scrollable-horizontal {
  overflow-x: auto;
}

.detail-information-column {
  display: flex;
  width: 50%;
  flex-direction: column;

  &.contact-person-column {
    width: 33.33%;
    //flex-wrap: wrap;

    @media (max-width: 960px) {
      width: 100%;
      //flex-wrap: nowrap;

      .detail-key {
        min-width: auto;
      }
    }
  }

  @media (max-width: 960px) {
    width: 100%;
  }
}

.btn-full-width-confirm {
  width: 100%;
  border: none;
  background: $gray;
  color: $white;
  padding: 10px 0;
  cursor: pointer;
}

.show-more-btn {
  width: 100%;
  font-size: 13px;
  padding: 3px 0;
  background: $hoverLight;
  color: $dark;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: darken($hoverLight, 5%);
  }

  &:active {
    background: $hoverLight;
  }
}

.detail-information-container {
  display: flex;
  flex-direction: column;
}

.not-clickable {
  cursor: default !important;

  &:active {
    opacity: 1 !important;
  }
}

.accordion-clickable-area {
  width: 100%;
  cursor: pointer;

  &:hover {
    background: rgba($dark, 0.4);
  }

  &:active {
    opacity: 0.6;
  }
}

.detail-information-header {
  background: $gradient;
  color: $white;
  font-size: 14px;
  padding: 3px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    display: flex;
    align-items: center;

    .pi-info-circle {
      cursor: default;
    }
  }

  &.for-contact-person {
    background: $main2;
  }

  a {
    text-decoration: none;
    color: $white;
  }

  .pi {
    margin: 0 10px 0 0;
  }

  .pi-info-circle,
  .pi-plus,
  .pi-download,
  .pi-chevron-up,
  .pi-chevron-down,
  .pi-pencil {
    margin: 0 0 0 10px;
    cursor: pointer;

    &:active {
      opacity: 0.6;
    }
  }

  &.is-accordion {
    padding: 0;
    border-top: 1px solid rgba($white, 0.6);

    &:first-of-type {
      border: none;
    }

    .pi-chevron-up,
    .pi-chevron-down,
    .pi-download {
      margin: 0 10px 0 0;
      padding: 5px 20px;

      &:hover {
        background: rgba($dark, 0.4);
      }
    }

    .pi-download {
      margin: 0;
    }
  }
}

.detail-information-current-time-table {
  td {
    font-size: 12px !important;
  }
}

.detail-information-body-container {
  display: flex;
}

.detail-information-banner {
  background: $alert;
  color: $white;
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;

  .pi-exclamation-triangle {
    font-size: 40px;
    padding: 15px 0 0 0;
    font-weight: normal;
  }

  a {
    color: $white;
  }

  &.clickable {
    cursor: pointer;
  }

  span {
    padding: 10px 20px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }

  small {
    padding: 0 20px 10px 20px;
  }

  &.light-version {
    background: $blue;

    span {
      font-weight: normal;
    }
  }

  &.warning-version {
    background: $orange;

    span {
      font-weight: normal;
    }
  }
}

.checklist-items {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.checklist-item {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;

  &:last-child {
    margin: 0;
  }

  > span {
    font-size: 16px;
  }

  i {
    font-size: 24px;
    margin: 0 10px 0 0;
  }
}

.detail-information-body {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;

  &.without-padding {
    padding: 0;
  }

  &.inline-information-body {
    flex-direction: row;
    padding: 0 10px;
    border-bottom: 1px solid $background;

    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
      border: none;
    }

    .detail-information {
      flex-direction: column;
      border-right: 1px solid $background;

      &:last-child {
        border: none;
      }
    }
  }

  .contact-person-column & {
    min-height: 170px;
  }

  &.smaller {
    width: 70%;
  }
}

.detail-information-subpanel {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: $gray;
  padding: 5px 10px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: $hoverLight;
  }

  &:active {
    background: darken($hoverLight, 10%);
  }
}

.detail-information-image {
  display: flex;
  width: 30%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  color: $gray;
  font-size: 14px;
}

.detail-information-line {
  display: flex;
}

.detail-information {
  display: flex;
  width: 100%;
  padding: 3px 10px;
  border-bottom: 1px solid $background;

  &.detail-information-big-table {
    flex-direction: column;
  }

  &.without-padding {
    padding: 0;
  }

  .inline-information-body & {
    border: none;
  }

  &:hover {
    background: $hoverLight;
  }

  &:last-child {
    margin: 0;
    border: none;
  }
}

.detail-key {
  display: flex;
  width: 40%;
  min-width: 240px;

  .contact-person-column & {
    min-width: auto;
  }

  @media (max-width: 1200px) {
    min-width: auto;
  }

  &.alert-key {
    span {
      font-weight: bold;
      color: $alert;
    }
  }

  .inline-information-body & {
    width: auto;
  }

  span {
    font-size: 13px;
    color: $lightDark;
    margin: 0 0 2px 0;
    word-break: break-word;
    cursor: default;
  }

  &.clickable {
    span {
      cursor: help !important;

      &:hover {
        text-decoration: underline;
      }

      &:active {
        text-decoration: none;
      }
    }
  }
}

.detail-value-actions {
  i {
    margin: 0 20px 0 0;
    cursor: pointer;

    &:active {
      opacity: 0.6;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.detail-value-search {
  display: flex;
  margin: 0 0 10px 0;

  input {
    height: 25px;
    width: 100%;
  }
}

.show-more-btn-light {
  display: flex;
  font-size: 11px !important;
  margin: 10px 0 10px 0;
  width: 100% !important;
  cursor: pointer;
  user-select: none;

  &:active {
    opacity: 0.6;
  }
}

.detail-value {
  display: flex;
  width: 60%;

  &.detail-value-with-actions {
    justify-content: space-between;
    align-items: center;

    .pi {
      cursor: pointer;
      color: $dark;
      margin: 0 10px 0 0;

      &:last-child {
        margin: 0;
      }

      &:active {
        opacity: 0.6;
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  .detail-information-big-table & {
    width: 100%;
  }

  &.veto-value {
    display: block;

    .pi {
      margin: 0 5px 0 0;
      color: $gray;
      font-size: 13px;
    }

    strong {
      display: flex;
      align-items: center;
      width: 50%;
      float: left;
    }
  }

  .veto-item {
    display: none !important;

    &.active {
      display: flex !important;
    }

    &.closed {
      display: none !important;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    font-size: 13px;

    th {
      text-align: left;
    }
  }

  strong {
    font-size: 13px;
    color: $dark;
    word-break: break-word;
  }

  a {
    color: $lightHighlightDarker;
    text-decoration: none;

    &:hover {
      color: $lightDark;
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
    }
  }
}

textarea {
  resize: vertical !important;
}

.patient-budget-container {
  background: $white;
  display: flex;
}

.patient-budget-types {
  background: $main1;
  width: $budgetTypeWidth;
  flex-shrink: 0;

  .clickable {
    cursor: pointer;

    &:active {
      opacity: 0.6;
    }
  }

  span {
    justify-content: flex-end;
    display: flex;
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid rgba($white, 0.3);
    align-items: center;

    .pi {
      margin: 0 10px 0 0;
    }

    &.highlight {
      background: rgba($dark, 0.2);
    }

    &:last-child {
      border: none;
    }
  }
}

.patient-budget-boxes {
  display: flex;
  overflow-x: scroll;
  min-width: calc(100% - #{$budgetTypeWidth});
}

.patient-budget-box {
  display: flex;
  min-width: $budgetBoxWidth;
  flex-direction: column;
  border-right: 5px solid $background;

  &:last-child {
    border: none;
  }
}

.patient-budget-box-header {
  display: flex;
  border-bottom: 1px solid $background;

  span {
    font-weight: bold;
    font-size: 11px;
    padding: 0 10px;
    text-transform: uppercase;
    color: $dark;
    border-right: 1px solid $background;
    height: 30px;
    align-items: center;
    display: flex;

    &:last-child {
      border: none;
    }
  }
}

.patient-budget-box-body {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    font-size: 14px;
    color: $dark;
    border-bottom: 1px solid $background;

    &.highlight {
      background: $hoverLight;
    }

    &.disabled {
      color: $lightHighlight;
    }

    &.finished {
      background: rgba($main2, 0.2);

      &.highlight {
        background: rgba($main2, 0.5) !important;
      }
    }

    &:last-child {
      border: none;
    }

    span {
      display: flex;
      justify-content: flex-end;
      padding: 5px 10px;
      align-items: center;
      // height: 29px;
      border-right: 1px solid $background;

      &:last-child {
        border: none;
      }

      .pi-pencil {
        cursor: pointer;
      }

      .pi {
        margin: 0 5px 0 0;
        color: $gray;

        &:hover {
          color: $dark;
        }
      }
    }
  }
}

.caregiver-times-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $lightGray;
  padding: 5px 10px;

  &:hover {
    background: $hoverLight;
  }

  &:last-child {
    border: none;
  }

  p-checkbox {
    // Damit der optische Abstand gleich ist.
    width: 200px;
    flex-shrink: 0;
  }

  p-dropdown {
    width: 100px !important;
  }

  .p-checkbox-label {
    font-weight: bold;
  }
}

.caregiver-times-lines {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.caregiver-times-line {
  display: flex;
  align-items: center;
  gap: 10px;

  &:last-child {
    margin: 0;
  }

  .pi-plus,
  .pi-minus {
    margin-left: auto;
    cursor: pointer;

    &:active {
      opacity: 0.6;
    }
  }
}

.overview-list-check {
  text-align: center !important;

  p-badge {
    position: absolute;
    top: -10px;
    right: -7px;

    span {
      font-size: 11px;
      min-width: 15px;
      height: 15px;
      line-height: 15px;
      font-weight: 600;
      background: $dark;
    }
  }

  .pi {
    font-size: 20px;
    cursor: pointer;
    position: relative;

    &:active {
      opacity: 0.5;
    }
  }
}

.caregiver-appointment-icon-container {
  position: relative;
  cursor: pointer;
  padding: 0 !important;

  &:active {
    opacity: 0.6;
  }

  p-badge {
    position: absolute;
    top: -10px;
    right: -7px;

    span {
      font-size: 12px;
      min-width: 17px;
      height: 17px;
      line-height: 17px;
      font-weight: 600;
      background: $gradient;
    }

    .p-badge-danger {
      background: $alert;
    }
  }
}

[styleclass="allergy-checkbox-container"] {
  width: 180px;
}

.kostentraeger-input-number {
  p-inputNumber {
    max-width: 90px !important;
  }
}

.copy-button {
  cursor: pointer;

  &:active {
    opacity: 0.6;
  }
}
