.p-datatable.highlight-header {
  th {
    background: $main2 !important;
    color: #fff !important;
    border: none !important;
    font-weight: normal !important;
  }
}

.p-datatable .p-datatable-tbody > tr {
  &:last-child {
    td {
      //border: none !important;
    }
  }
}

.p-datatable .p-datatable-thead > tr > th {
  background: $white;
  color: $dark;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 3px 5px;
  border-bottom: 1px solid $background;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  //position: sticky;
  //top: -30px;

  &.p-sortable-column:active {
    opacity: 0.5;
  }

  &:first-child {
    padding: 3px 5px 3px 20px;
  }

  &:last-child {
    padding: 3px 20px 3px 5px;
  }
}

.table-search-container {
  .pi-filter {
    font-size: 14px;
    color: $gray;
    margin: 0 20px 0 0;
    cursor: pointer;
    font-style: normal;

    &:active {
      opacity: 0.6;
    }
  }
}

.p-datatable .clickable {
  cursor: pointer;

  &:active {
    background: darken($hoverLight, 5%) !important;
  }
}

.p-datatable .p-datatable-thead > tr {
  //background: $gradient;
}

tr {
  &.finished {
    background: rgba($main2, 0.2) !important;
  }

  small {
    font-size: 12px;
    color: $lightDark;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 3px 5px;
  word-break: break-word;
  font-size: 14px;
  color: $lightDark;
  border-bottom: 1px solid $background;

  &:first-child {
    padding: 3px 5px 3px 20px;
  }

  &:last-child {
    padding: 3px 20px 3px 5px;
  }

  .smaller-size-table & {
    font-size: 13px;
  }
}

.table-no-overflow {
  .p-datatable-wrapper {
    overflow: unset !important;
  }
}

.filter-table-headline {
  text-transform: none !important;

  > * {
    width: 90% !important;
    max-width: 90% !important;
  }

  .p-dropdown {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.p-datatable-footer {
  position: sticky;
  bottom: -10px;
  padding: 8px 20px !important;
  font-size: 13px;
  color: $dark !important;
}

.table-footer {
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

  .table-footer-number {
    width: 80px;
    margin: 0 10px 0 0;
    text-align: right;
  }

  .table-footer-info {
  }
}

.table-row-highlight {
  box-shadow: 0 0 0 4px inset $blue !important;
}

.appointment-release-amount {
  color: $gray;
  font-size: 13px;
  margin: 0 0 0 10px;
}

.with-margin-right {
  margin-right: 20px;
}

.table-action {
  text-align: right !important;
  justify-content: flex-end;

  .pi-info-circle {
    font-size: 20px;
  }

  i {
    cursor: pointer;
    color: $gray;

    &:hover {
      color: $lightDark;
    }

    &:active {
      color: $gray;
    }
  }
}

.show-always {
  display: block !important;
}

.p-datatable-tbody {
  tr {
    &:hover {
      .pi-pencil {
        display: inline-block;
      }
    }
  }

  .pi-pencil {
    color: $gray;
    display: none;
    cursor: pointer;

    &:active {
      color: $lightGray;
    }
  }

  .pi-info-circle {
    color: $gray;
    font-size: 20px;
  }

  .pi-phone,
  .pi-user,
  .pi-clock,
  .pi-file,
  .pi-send {
    font-size: 13px;
    color: $gray;
  }

  a {
    color: $lightHighlightDarker;
    text-decoration: none;

    &:hover {
      color: $lightDark;
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
    }
  }
}

.p-datatable .p-sortable-column.p-highlight {
  color: $main1 !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  font-size: 12px;
  color: $main1 !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-badge {
  color: $dark;
  font-size: 12px;
  margin-left: 2px !important;
  background: none !important;
  width: auto !important;
  height: auto !important;
}

.p-datatable {
  .pi-filter-icon,
  .pi-filter-slash {
    font-size: 12px;
  }
}

.table-header-with-inline-filter {
  .p-multiselect {
    width: 100% !important;
  }

  .p-multiselect .p-multiselect-label {
    font-size: 12px !important;
  }
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
  //height: auto !important;
  background: none !important;
  box-shadow: none !important;

  &:active {
    opacity: 0.6;
  }
}

.pi-sort-alt::before {
  content: "";
}

.pi-sort-amount-up-alt::before {
  content: "\e91c";
}

.pi-sort-amount-down::before {
  content: "\e919";
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: $white;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: none !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.p-datatable .p-sortable-column.p-highlight {
  background: $white !important;
}

.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-tfoot {
  background: $white !important;
}

.p-datatable-scrollable-view {
  .stretch-full-max & {
    height: calc(100vh - 275px);
  }
}

.p-paginator {
  padding: 0 10px !important;
  border-top: 1px solid $background !important;

  @media (max-width: 1000px) {
    justify-content: flex-start !important;
  }
}

//.p-paginator-element {
//  border-radius: 0 !important;
//  min-width: 50px !important;
//  height: 30px !important;
//
//  &:hover {
//    background: transparent !important;
//    box-shadow: 0 0 0 3px rgba($lightHighlight, 0.5) !important;
//  }
//
//  &:focus {
//    box-shadow: 0 0 0 3px rgba($lightHighlight, 0.5) !important;
//  }
//
//  &:active {
//    box-shadow: 0 0 0 1px rgba($lightHighlight, 0.5) !important;
//  }
//}

//.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
//  background: $hoverLight !important;
//}

.p-paginator-current {
  font-size: 15px !important;
  height: auto !important;
  margin-right: auto !important;
}

.p-paginator-first {
  margin-left: auto !important;
}

.p-paginator-element {
  background: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 32px !important;
  min-width: 25px !important;
  border-bottom: 3px solid transparent !important;

  &:hover {
    border-bottom: 3px solid $lightHighlight !important;
  }

  &:focus {
    border-bottom: 3px solid $lightHighlight !important;
  }

  &:active {
    border-bottom: 3px solid $gray !important;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: none !important;
  border: none !important;
  font-weight: 600 !important;
  background: $gradient !important;
  color: $white !important;
  border-bottom: 3px solid $gradient !important;
}

.p-paginator .p-dropdown {
  height: 25px !important;
  display: flex !important;
  align-items: center;
}

.history-information {
  display: flex;
  flex-direction: column;

  i {
    font-style: normal;
    margin-right: 10px;

    &:last-child {
      margin: 0;
    }
  }

  .pi-file {
    margin-left: 5px !important;
  }
}

.history-appointment-item {
  display: flex;
  align-items: center;

  .pi-arrow-right {
    font-size: 12px;
    color: $gray;
    margin: 0 5px;
  }

  .pi-info-circle {
    font-size: 14px;
    color: $gray;
  }
}

.text-nowrap {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-text {
  white-space: pre-line;

  table {
    font-size: 13px;
    margin: 10px 0 0 0;

    th {
      width: 12%;
    }
  }
}

.table-current-date {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  background: $blue;
  height: 100%;
}

.table-data-highlight {
  color: $main1;
}

.table-small-info {
  font-size: 11px;
  opacity: 0.8;
}

.td-with-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  &.td-align-left {
    justify-content: flex-start;
  }

  > div {
    width: 70px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .pi-plus-circle,
  .pi-minus-circle {
    color: $lightHighlight;

    &:hover {
      color: $main1;
    }
  }

  .pi {
    cursor: pointer;
    font-size: 20px;
    padding: 10px;

    &:active {
      opacity: 0.6;
      color: $lightHighlight;
    }
  }
}

.text-center-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.show-on-mobile {
  display: none !important;
}

@media screen and (max-width: 960px) {
  .p-datatable-responsive-stack {
    .table-action {
      * {
        margin-left: auto !important;
      }
    }

    td {
      flex-direction: column;
      align-items: flex-start !important;
      padding: 5px 20px !important;

      &:first-child {
        padding-top: 10px !important;
      }

      &:last-child {
        padding-bottom: 10px !important;
      }

      &.td-with-multiple-items {
        align-items: center !important;
      }
    }

    .p-column-title {
      padding: 0.4rem;
      min-width: 30%;
      margin: -0.4em 1em -0.4em -0.4rem;
      font-weight: bold;
      display: inline-block;
    }

    .hide-row-on-mobile {
      display: none !important;
    }

    .show-on-mobile {
      display: flex !important;
    }
  }
}

@media (min-width: 961px) {
  .p-column-title {
    display: none !important;
  }
}

.td-with-multiple-items {
  @media (max-width: 960px) {
    flex-direction: row !important;
    justify-content: space-between !important;
  }
}

.table-headline-highlight {
  background: $gradient;

  th {
    background: transparent !important;
    color: $white !important;
    border: none !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-transform: none !important;
  }

  .p-sortable-column.p-highlight {
    color: $white !important;
    background: $gradient !important;
  }

  .p-sortable-column.p-highlight .p-sortable-column-icon {
    font-size: 12px;
    color: $dark !important;
  }
}
